const BulletedList = ({ className = '' }) => (
  <ul className={className}>
    <li>
      New bathtub and shower modifications can reduce the risk of slips and
      falls.
    </li>
    <li>Replacements are designed with comfort in mind.</li>
    <li>Select models provide a luxurious and soothing bathing routine.</li>
    <li>
      By proactively upgrading your bathtub and shower, you&apos;re investing in
      your safety, comfort, and overall quality of life.
    </li>
  </ul>
);

export default BulletedList;
