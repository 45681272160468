import './mainForm.css';
import './steps/formSteps.css';

import { FormProvider, useForm } from 'react-hook-form';
import { FormStep1, FormStep2, FormStep3 } from './steps';
import { getErrorMessage, submitForm } from '../../lib/utils';
import {
  useAppContext,
  useFormDataContext,
  useLendersContext,
  useLocation,
} from '../../lib/contexts/';

import BBBLogo from '../../assets/img/logo_bbb.png';
import { BulletedList } from '..';
import { CustomLoader } from '../CustomComponents';
import isEmpty from 'lodash/isEmpty';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

function MainForm() {
  const navigate = useNavigate();
  const { setLendersContext } = useLendersContext();
  const { formData, clearStorage } = useFormDataContext();
  const { location } = useLocation();
  const { appContext } = useAppContext();
  const { step } = formData;
  const [isLoading, setIsLoading] = useState(false);
  const methods = useForm({ defaultValues: formData, mode: 'onSubmit' });
  const { formState } = methods;
  const { errors } = formState;

  const handleGlobalSubmit = async (data) => {
    setIsLoading(true);

    const result = await submitForm({ ...data, ...appContext });

    const lendersContextData = {
      name: `${formData.firstname} ${formData.lastname}`,
      zipCode: formData.zip,
    };

    if (result.status === 'matched') {
      lendersContextData.lenders = result.lenders;
    }

    if (result.pixel) {
      lendersContextData.pixel = result.pixel;
    }

    setLendersContext(lendersContextData);

    const route =
      result.status === 'matched'
        ? '/thank-you/lender-list'
        : '/thank-you/alternative';

    clearStorage();
    navigate(route);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <CustomLoader />
      ) : (
        <>
          <div className="main-form__wrapper">
            <div className="main-form">
              <div>
                <h2 className="main-form__title text-lg-start">
                  Prioritizing Safety for {location} Residents: Transform Your
                  Bathing Experience.
                </h2>
                <h3 className="main-form__subtitle">
                  Find out how much you can save in just 30 seconds!
                </h3>
                <BulletedList className="main-form__list desktop-only" />
              </div>

              <FormProvider {...methods}>
                <form
                  className="main-form__tag"
                  onSubmit={methods.handleSubmit(handleGlobalSubmit)}
                >
                  {!isEmpty(errors) && (
                    <div className="form-step__error-box">
                      {getErrorMessage(errors)}
                    </div>
                  )}
                  <div className="main-form__step-container">
                    <div className="main-form__step-info">STEP {step} of 3</div>
                    {step === 1 && <FormStep1 />}
                    {step === 2 && <FormStep2 />}
                    {step === 3 && <FormStep3 />}

                    {step === 1 && (
                      <div className="main-form__img-container">
                        <img
                          className="main-form__img"
                          src={BBBLogo}
                          alt="Accredit Business"
                        />
                      </div>
                    )}
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>

          <BulletedList className="main-form__list mobile-only" />
        </>
      )}
    </>
  );
}

export default MainForm;
