import { createContext, useContext, useState } from 'react';

const defaultState = {
  address: '',
  city: '',
  email: '',
  firstname: '',
  homeowner: 'yes',
  lastname: '',
  phone: '',
  projecttype: '',
  state: '',
  zip: '',
  step: 1,
  addremovewalls: 'unsure', // TEMP FIX: This should be removed as the API should not request this param.
};

const FormDataContext = createContext();

export const useFormDataContext = () => useContext(FormDataContext);

export const FormDataProvider = ({ children }) => {
  const [formData, setFormData] = useState(defaultState);

  const updateFormData = (data) => {
    setFormData({ ...formData, ...data });
  };

  const clearStorage = () => setFormData(defaultState);

  const updateStep = (step) => {
    setFormData({
      ...formData,
      step,
    });
  };

  return (
    <FormDataContext.Provider
      value={{ formData, updateFormData, updateStep, clearStorage }}
    >
      {children}
    </FormDataContext.Provider>
  );
};
