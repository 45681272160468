import {
  AppProvider,
  DisclaimerProvider,
  FormDataProvider,
  LenderProvider,
  LocationProvider,
} from './';

import { HelmetProvider } from 'react-helmet-async';

const CombinedProviders = ({ children }) => (
  <HelmetProvider>
    <AppProvider>
      <LenderProvider>
        <LocationProvider>
          <FormDataProvider>
            <DisclaimerProvider>{children}</DisclaimerProvider>
          </FormDataProvider>
        </LocationProvider>
      </LenderProvider>
    </AppProvider>
  </HelmetProvider>
);

export default CombinedProviders;
