import {
  ADDRESS_ERROR_MESSAGE,
  ADDRESS_PATTERN_ERROR_MESSAGE,
  ADDRESS_REGEX,
  CITY_ERROR_MESSAGE,
  EMAIL_ERROR_MESSAGE,
  FIRST_NAME_ERROR_MESSAGE,
  LAST_NAME_ERROR_MESSAGE,
  NAME_REGEX,
  PHONE_ERROR_MESSAGE,
  STATES_OPTIONS,
  STATE_ERROR_MESSAGE,
  US_PHONE_REGEX,
} from '../../../lib/constants';
import {
  CustomInput,
  CustomPhoneInput,
  CustomSelect,
  CustomSubmit,
} from '../../CustomComponents';
import {
  onlyLettersInput,
  validateEmail,
  validatePhoneNumber,
} from '../../../lib/utils';

import { useFormContext } from 'react-hook-form';
import { useFormDataContext } from '../../../lib/contexts';
import { useScrollToTop } from '../../../lib/hooks';

function FormStep3() {
  const { register, formState } = useFormContext();
  const { errors } = formState;
  const { updateFormData, formData } = useFormDataContext();
  useScrollToTop();
  return (
    <div className="form-step3">
      <div className="form-step">
        <div className="form-step__label">Your contact information</div>

        <div className="form-step__input-group">
          <CustomInput
            type="text"
            placeholder="First Name"
            name="firstname"
            onInput={onlyLettersInput}
            register={register}
            onInputChange={updateFormData}
            validation={{
              required: FIRST_NAME_ERROR_MESSAGE,
              pattern: {
                value: NAME_REGEX,
                message: FIRST_NAME_ERROR_MESSAGE,
              },
            }}
          />

          <CustomInput
            type="text"
            placeholder="Last Name"
            name="lastname"
            onInput={onlyLettersInput}
            register={register}
            onInputChange={updateFormData}
            validation={{
              required: LAST_NAME_ERROR_MESSAGE,
              pattern: {
                value: NAME_REGEX,
                message: LAST_NAME_ERROR_MESSAGE,
              },
            }}
          />
        </div>

        <CustomInput
          type="text"
          placeholder="Street Address (no city/state/zip)"
          name="address"
          register={register}
          onInputChange={updateFormData}
          validation={{
            required: ADDRESS_ERROR_MESSAGE,
            pattern: {
              value: ADDRESS_REGEX,
              message: ADDRESS_PATTERN_ERROR_MESSAGE,
            },
          }}
        />

        <CustomInput
          type="text"
          placeholder="City"
          name="city"
          register={register}
          value={formData.city}
          onInput={onlyLettersInput}
          onInputChange={updateFormData}
          validation={{
            required: CITY_ERROR_MESSAGE,
            message: CITY_ERROR_MESSAGE,
          }}
        />

        <CustomSelect
          options={STATES_OPTIONS}
          name="state"
          placeholder="Please Select State"
          value={formData.state}
          register={register}
          onChange={updateFormData}
          errorMessage={STATE_ERROR_MESSAGE}
          errors={errors}
          disableMargin={true}
        />

        <div className="form-step__input-group form-step__input-group__no-margin">
          <CustomInput
            type="text"
            placeholder="Email Address"
            name="email"
            register={register}
            onInputChange={updateFormData}
            validation={{
              required: EMAIL_ERROR_MESSAGE,
              validate: validateEmail,
            }}
          />

          <CustomPhoneInput
            name="phone"
            placeholder="Phone (+1 is not used)"
            mask={US_PHONE_REGEX}
            autoComplete="off"
            validation={{
              required: PHONE_ERROR_MESSAGE,
              validate: validatePhoneNumber,
            }}
          />
        </div>

        <CustomSubmit />
      </div>
    </div>
  );
}

export default FormStep3;
