import './thankYouLenderList.css';

import React, { useEffect, useRef } from 'react';
import parse, { domToReact } from 'html-react-parser';
import { useConditionalNavigation, useScrollToTop } from '../../lib/hooks';

import { CustomLink } from '../CustomComponents';
import Disclaimer from '../Disclaimer/Disclaimer';
import isEmpty from 'lodash/isEmpty';
import { useLendersContext } from '../../lib/contexts';

function ThankYouLenderList() {
  const { name, lenders = [], pixel } = useLendersContext();
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'command',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  const lenderContainerRef = useRef(null);

  const stopPropagation = (event) => {
    event.stopImmediatePropagation();
  };

  useEffect(() => {
    const container = lenderContainerRef.current;
    if (container) {
      const linkElements = container.querySelectorAll(
        '.thank-you-lenders__description .thank-you-lenders__description-bulletpoints ul li a'
      );

      linkElements.forEach((elem) =>
        elem.addEventListener('click', stopPropagation, true)
      );

      return () => {
        linkElements.forEach((elem) =>
          elem.removeEventListener('click', stopPropagation)
        );
      };
    }
  }, []);

  const parseOptions = {
    replace: ({ attribs, children, name }) => {
      if (!attribs) {
        return;
      }

      const newAttribs = { ...attribs };
      delete newAttribs.style;
      delete newAttribs.className;

      if (attribs.href) {
        return (
          <CustomLink {...newAttribs}>
            {domToReact(children, parseOptions)}
          </CustomLink>
        );
      }

      if (voidElements.has(name)) {
        return React.createElement(name, newAttribs);
      } else {
        return React.createElement(
          name,
          newAttribs,
          domToReact(children, parseOptions)
        );
      }
    },
  };

  useConditionalNavigation(!name || !lenders, '/');
  useScrollToTop();
  const parsedPixel = parse(pixel || '');

  return (
    <div className="thank-you-lenders__container">
      {pixel && <div className="thank-you-lenders__pixel">{parsedPixel}</div>}
      {isEmpty(lenders) ? (
        <>
          <div className="thank-you-lenders__title">
            Thanks for your request {name}!
          </div>
          <div className="thank-you-lenders__title">
            Congratulations! You have been matched with our home improvement
            partners who will call you shortly to complete your free quote
          </div>
        </>
      ) : (
        <div className="thank-you-lenders__title">
          Thanks for your request {name}! We have matched you with the following
          bath provider.
        </div>
      )}

      <div className="thank-you-lenders__list">
        {lenders.map(({ logo, name, bulletpoints, link, phone }, index) => {
          const parsedBulletPoints = parse(bulletpoints, parseOptions);

          return (
            <div
              className="thank-you-lenders__card-wrapper"
              key={`${name}-${index}`}
            >
              <div
                className="thank-you-lenders__card"
                ref={lenderContainerRef}
                onClick={() => {
                  if (link) window.open(link, '_blank');
                }}
              >
                <div className="thank-you-lenders__img">
                  <img src={logo} alt="matchedLogo" />
                </div>

                <div className="thank-you-lenders__description">
                  <div className="thank-you-lenders__description-header">
                    <span>{name}</span>
                  </div>
                  <div className="thank-you-lenders__description-bulletpoints">
                    {parsedBulletPoints}
                  </div>
                </div>

                {link && (
                  <div className="thank-you-lenders__quote-container">
                    <a
                      className="thankyou__lenders-quote-btn"
                      target="_blank"
                      href={link}
                      rel="noreferrer"
                    >
                      GET YOUR QUOTE!
                    </a>
                  </div>
                )}
              </div>
              {phone && (
                <h2
                  className="thank-you-lenders__phone"
                  onClick={() => window.open(`tel:${phone}`, '_blank')}
                >
                  {`Get the Help You Need - Call Now and Speak to an Expert ${phone}`}
                </h2>
              )}
            </div>
          );
        })}
      </div>
      <div className="disclaimer" style={{ width: '100%' }}>
        <Disclaimer />
      </div>
    </div>
  );
}

export default ThankYouLenderList;
