import './topBarAndFooter.css';

import GNBTLogo from '../../assets/img/logo.png';

function TopBarAndFooter({ className = '' }) {
  return (
    <div className={`top-bar-and-footer ${className}`}>
      <div className="top-bar-and-footer__container">
        <a href="/" className="top-bar-and-footer__link">
          <img
            src={GNBTLogo}
            alt="Logo"
            className="top-bar-and-footer__image"
          />
        </a>
      </div>
    </div>
  );
}

export default TopBarAndFooter;
