import './customLoader.css';

import MoonLoader from 'react-spinners/MoonLoader';
import React from 'react';
import { useScrollToTop } from '../../../lib/hooks';

function CustomLoader() {
  const MainContentTitle = ({ children }) => (
    <div className="page-loader__main-content-title">{children}</div>
  );

  const MainContentText = ({ children }) => (
    <div className="page-loader__main-content-text">{children}</div>
  );

  useScrollToTop();

  return (
    <div className="page-loader__container">
      <div className="page-loader__box page-loader__box-size">
        <MainContentTitle>
          Thank You For Submitting Your Home Improvement Request!
        </MainContentTitle>
        <MainContentText>
          Our team is diligently working to match your specific request with
          top-tier home services companies.
        </MainContentText>
        <MainContentText>
          A home services representative will be calling you shortly to discuss
          your objectives and complete your free quote
        </MainContentText>
        <MainContentText>
          Your home services company may take up to 60 seconds to display as we
          look for the perfect match. We apologize for any delay.
        </MainContentText>
        <MoonLoader
          color={'#2cb701'}
          loading={true}
          size={100}
          cssOverride={{
            alignSelf: 'center',
          }}
          aria-label="Loading Spinner"
          data-testid="loader"
          speedMultiplier={0.2}
        />
      </div>
    </div>
  );
}

export default CustomLoader;
